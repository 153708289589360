import React from "react";
import theme from "theme";
import { Theme, Text, Em, List, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				I AM CAT SIMULATOR
			</title>
			<meta name={"description"} content={"Embark on a whimsical journey as a playful cat, exploring the world, causing mischief, and living a life full of purr-sonality in *I Am Cat Simulator*."} />
			<meta property={"og:title"} content={"I AM CAT SIMULATOR"} />
			<meta property={"og:description"} content={"Embark on a whimsical journey as a playful cat, exploring the world, causing mischief, and living a life full of purr-sonality in *I Am Cat Simulator*."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67349b1628b5e30023ae0799/images/DALL_E_2024-11-13_15.29.15_-_A_cute_and_tiny_favicon_for_the_game__I_Am_Cat_Simulator__in_a_cartoon_style__featuring_a_small__playful_cat_face_with_an_animated_expression._The_des-removebg-preview.png?v=2024-11-13T13:35:36.185Z"} />
		</Helmet>
		<Components.Header />
		<Section>
			{"    "}
			<Text font="--headline2">
				{"        "}Terms and Conditions{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}
				<Em>
					{"            "}Last Updated: 20.10.2024{"\n        "}
				</Em>
				{"    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}1. Acceptance of Terms{"\n    "}
			</Text>
			{"    "}
			<Text>
				By downloading or using I AM CAT SIMULATOR GAMES, you affirm that you are at least 18 years old or that you have the consent of a parent or guardian. If you are accessing the game on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}2. License to Use the Game{"\n    "}
			</Text>
			{"    "}
			<Text>
				We grant you a limited, non-exclusive, non-transferable, revocable license to access and use I AM CAT SIMULATOR GAMES for personal, non-commercial purposes. You agree not to modify, distribute, or exploit any part of the game without our express written permission.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}3. User Responsibilities{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}You are responsible for your use of the game and for any content you create, share, or interact with while using I AM CAT SIMULATOR GAMES. You agree not to engage in any unlawful or prohibited activities, including but not limited to:{"\n    "}
			</Text>
			{"    "}
			<List list-style-type="circle" as="dot">
				<Text display="inline">
					Harassment or intimidation of other users
				</Text>
				<Text display="inline">
					Impersonation of any person or entity
				</Text>
				<Text display="inline">
					Interference with the operation of the game
				</Text>
			</List>
			{"    "}
			<Text font="--headline3">
				{"        "}4. Intellectual Property{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}All content, trademarks, and other intellectual property in I AM CAT SIMULATOR GAMES are owned by I AM CAT SIMULATOR GAMES or its licensors. You may not use, reproduce, or distribute any materials from the game without our prior written consent.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}5. Limitation of Liability{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of I AM CAT SIMULATOR GAMES.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline3">
				{"        "}6. Changes to Terms{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on our website. Your continued use of the game following such changes constitutes your acceptance of the new Terms.{"\n    "}
			</Text>
			{"            "}
		</Section>
		<Components.Footer2 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});